import { useApi } from '~/composables/api/useApi'

export function useInvitations() {
  const { fetchWithAuth } = useApi()
  const config = useRuntimeConfig()
  const invitationsAPI =
    config.public.invitationsAPI || 'https://api-invitation.transkribus.org'
  const apiVersion = 'v1'
  const COLLECTION = 'collection'
  const ORGANISATION = 'organisation'
  const SITE = 'site'

  // GET Requests
  async function fetchCollectionInvitations(providedOptions: {
    collId: number
    page?: number
    size?: number
    sortField?: string
    sortDirection?: string
    state: 'PENDING' | 'ACCEPTED' | 'REJECTED'
  }) {
    return fetchWithAuth(
      `${invitationsAPI}/${apiVersion}/${COLLECTION}/${providedOptions.collId}`,
      'GET',
      {
        providedOptions: {
          page: providedOptions.page,
          size: providedOptions.size,
          sortField: providedOptions.sortField,
          sortDirection: providedOptions.sortDirection,
          state: providedOptions.state,
        },
      }
    )
  }

  async function fetchSitesInvitations(providedOptions: {
    sitesId: number
    page?: number
    size?: number
    sortField?: string
    sortDirection?: string
    state: 'PENDING' | 'ACCEPTED' | 'REJECTED'
  }) {
    return fetchWithAuth(
      `${invitationsAPI}/${apiVersion}/${SITE}/${providedOptions.sitesId}`,
      'GET',
      {
        providedOptions: {
          page: providedOptions.page,
          size: providedOptions.size,
          sortField: providedOptions.sortField,
          sortDirection: providedOptions.sortDirection,
          state: providedOptions.state,
        },
      }
    )
  }

  async function fetchSubscriptionInvitations(providedOptions: {
    organisationId: number
    page?: number
    size?: number
    sortField?: string
    sortDirection?: string
    state: 'PENDING' | 'ACCEPTED' | 'REJECTED'
  }) {
    return fetchWithAuth(
      `${invitationsAPI}/${apiVersion}/${ORGANISATION}/${providedOptions.organisationId}`,
      'GET',
      {
        providedOptions: {
          page: providedOptions.page,
          size: providedOptions.size,
          sortField: providedOptions.sortField,
          sortDirection: providedOptions.sortDirection,
          state: providedOptions.state,
        },
      }
    )
  }

  async function fetchMyInvitations(providedOptions: {
    state: string
    page?: number
    size?: number
    sortField?: string
    sortDirection?: string
  }) {
    return fetchWithAuth(`${invitationsAPI}/${apiVersion}/invitations`, 'GET', {
      providedOptions: {
        state: providedOptions.state,
        page: providedOptions.page,
        size: providedOptions.size,
        sortField: providedOptions.sortField,
        sortDirection: providedOptions.sortDirection,
      },
    })
  }

  async function fetchInvitationById(providedOptions: { inviteId: string }) {
    return fetchWithAuth(
      `${invitationsAPI}/${apiVersion}/invitations/${providedOptions.inviteId}`,
      'GET'
    )
  }

  // POST Requests

  async function sendCollectionInvitation(providedOptions: {
    collId: number
    inviteeEmail: string
    role: string
    message: string
  }) {
    const { collId, inviteeEmail, role, message } = providedOptions
    return fetchWithAuth(
      `${invitationsAPI}/${apiVersion}/${COLLECTION}/${collId}`,
      'POST',
      {
        data: { inviteeEmail, role, message },
      }
    )
  }

  async function createAnonymousInvitation(providedOptions: {
    collId: number
    role: string
    message: string
  }) {
    const { collId, role, message } = providedOptions
    return fetchWithAuth(
      `${invitationsAPI}/${apiVersion}/${COLLECTION}/${collId}`,
      'POST',
      {
        data: { role, message },
      }
    )
  }

  async function createAnonymousSiteInvitation(providedOptions: {
    sitesId: number
    message: string
  }) {
    const { sitesId, message } = providedOptions
    return fetchWithAuth(
      `${invitationsAPI}/${apiVersion}/${SITE}/${sitesId}`,
      'POST',
      {
        data: { role: 'Editor', message },
      }
    )
  }

  async function createAnonymousOrgaInvitation(providedOptions: {
    organisationId: number
    role: string
    message: string
  }) {
    const { organisationId, role, message } = providedOptions
    return fetchWithAuth(
      `${invitationsAPI}/${apiVersion}/${ORGANISATION}/${organisationId}`,
      'POST',
      {
        data: { role, message },
      }
    )
  }

  async function sendSitesInvitation(providedOptions: {
    sitesId: number
    inviteeEmail: string
    role: string
    message: string
  }) {
    const { sitesId, inviteeEmail, role, message } = providedOptions
    return fetchWithAuth(
      `${invitationsAPI}/${apiVersion}/${SITE}/${sitesId}`,
      'POST',
      {
        data: { inviteeEmail, role, message },
      }
    )
  }

  async function sendSubscriptionInvitation(providedOptions: {
    organisationId: number
    inviteeEmail: string
    role: string
    message: string
  }) {
    const { organisationId, inviteeEmail, role, message } = providedOptions
    return fetchWithAuth(
      `${invitationsAPI}/${apiVersion}/${ORGANISATION}/${organisationId}`,
      'POST',
      {
        data: { inviteeEmail, role, message },
      }
    )
  }

  async function acceptOrRejectInvitation(providedOptions: {
    inviteId: string
    state: string
  }) {
    const { inviteId, state } = providedOptions
    return fetchWithAuth(
      `${invitationsAPI}/${apiVersion}/invitations`,
      'POST',
      {
        data: { inviteId, state },
      }
    )
  }

  async function acceptInvitation(providedOptions: { inviteId: string }) {
    return acceptOrRejectInvitation({
      inviteId: providedOptions.inviteId,
      state: 'ACCEPTED',
    })
  }

  async function rejectInvitation(providedOptions: { inviteId: string }) {
    return acceptOrRejectInvitation({
      inviteId: providedOptions.inviteId,
      state: 'REJECTED',
    })
  }

  // DELETE Requests

  async function deleteCollectionInvitation(providedOptions: {
    collId: number
    inviteId: string
  }) {
    const { inviteId } = providedOptions
    return fetchWithAuth(
      `${invitationsAPI}/${apiVersion}/${COLLECTION}/${providedOptions.collId}/${inviteId}`,
      'DELETE'
    )
  }

  async function deleteSitesInvitation(providedOptions: {
    sitesId: number
    inviteId: string
  }) {
    const { inviteId } = providedOptions
    return fetchWithAuth(
      `${invitationsAPI}/${apiVersion}/${SITE}/${providedOptions.sitesId}/${inviteId}`,
      'DELETE'
    )
  }

  async function deleteSubscriptionInvitation(providedOptions: {
    organisationId: number
    inviteId: string
  }) {
    const { inviteId } = providedOptions
    return fetchWithAuth(
      `${invitationsAPI}/${apiVersion}/${ORGANISATION}/${providedOptions.organisationId}/${inviteId}`,
      'DELETE'
    )
  }

  return {
    fetchCollectionInvitations,
    fetchSitesInvitations,
    sendCollectionInvitation,
    sendSitesInvitation,
    createAnonymousInvitation,
    createAnonymousSiteInvitation,
    createAnonymousOrgaInvitation,
    fetchSubscriptionInvitations,
    sendSubscriptionInvitation,
    fetchMyInvitations,
    fetchInvitationById,
    acceptInvitation,
    rejectInvitation,
    deleteCollectionInvitation,
    deleteSitesInvitation,
    deleteSubscriptionInvitation,
  }
}
